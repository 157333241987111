.footer {
  height: 100px;
  width: 700px;
  background-color: #f2f2f2;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h3 {
  color: #9e9e9e;
  font-weight: 400;
  text-align: center;
  word-break: break-all;
  margin: 5px;
}

.links-footer {
  width: 40%;
  min-width: 120px;
  display: flex;
  justify-content: center;
}

.links-footer a {
  margin: 0 7px;
}

@media only screen and (max-width: 700px) {
  .footer {
    width: 100%;
  }
}
